import React, { useState, useEffect } from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import styles from "./style.module.scss";

import Button from "../../components/button/button";
import Rating from "../../components/rating/rating";
import CarPreview from "../../components/carPreview/carPreview";
import MyCarousel from '../../components/carousel/carousel';
import Carousel from 'react-multi-carousel';

import {ajaxGet, ajaxPost} from "../../helper/ajax";
import { useNavigate } from "react-router-dom";
import { BASENAME } from "../../constant";
import {isMobile} from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Home() {
    const navigate = useNavigate();

    const [carList, setCarList] = useState([]);
    const [porscheList, setporscheList] = useState([]);
    const [filterList, setFilterList] = useState({});

    const [carType, setCarType] = useState("usato");
    const [carBrand, setCarBrand] = useState(null);
    const [carModel, setCarModel] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);
    const [search, setSearch] = useState("");

    useEffect(() => {
        ajaxGet("getVclFeatured.php").then((response) => {
            if (response.code == "100") {
                
                setCarList(filterFeatured(response.message))
            }
        });

        let formdata = new FormData();
        formdata.append("brand", "PORSCHE");
        ajaxPost("getVclList.php", formdata).then((response) => {
            if (response.code == "100") {
                setporscheList(response.message.car)
            }
        });

        ajaxGet("getFilters.php").then((response) => {
            if (response.code == "100") {
                setFilterList(response.message);
            }
        });

    }, [])

    let responsive = {
        desktop: {
            breakpoint: { max: 5000, min: 1024 },
            items:1

        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    const redirectToSearch = (val) => {
        navigate('veicoli', {
            state: {
                search: val
            }
        })
    }

    const filterFeatured = arr => {
        arr.sort((a, b) => (new Date(a.StartData) < new Date(b.StartData)) ? 1 : (new Date(a.StartData) > new Date(b.StartData)) ? -1 : 0)
        let temp = [];
        arr.map((el) => {
            if (temp.length >= 9) { return }

            if (el.ClasseComm == "usato") {
                if (el.Immatricolazione) {
                    let year = el.Immatricolazione.substr(el.Immatricolazione.length - 4);
                    let currentYear = new Date().getFullYear();
                    if (currentYear - year <= 2) {
                        console.log("AA")
                        temp.push(el)
                    }
                }
            } else {
                temp.push(el)
            }
        })
        return temp;
    }


    return (
        <Layout>

            <div className="d-sm-block d-md-none">
                <div className={styles.homeSearchContainer}>
                    <div className={styles.inputWithIcon}>
                        <input
                            placeholder="Cerca la tua auto"
                            onBlur={(val) => setSearch(val.target.value)}
                            onKeyUp={(val) => { if (val.key == "Enter") { redirectToSearch(val.target.value) } }}
                        />
                        <img src="site_images/icon/icon-lense.png" onClick={() => redirectToSearch(search)}/>
                    </div>
                </div>
            </div>

            <Carousel
                responsive={responsive}
                arrows={isMobile ? false : true}
                showDots={true}
                containerClass={"HomeCarousel"}
            >

                <img src="site_images/bg_hero_home.png" className={styles.homeSlider} />
                <img src="site_images/bg_hero_home.png" className={styles.homeSlider} />
                <img src="site_images/bg_hero_home.png" className={styles.homeSlider} />
            </Carousel>

            <section className='sections_home' style={{ marginBottom: 0 }}>
                <div className="container">
                    <div className={styles.searchContainer}>
                        <div className={styles.searchButtonContainer}>

                            <input type="radio" className="btn-check" value="semestrale" name="carType" id="option3" defaultChecked onChange={(val) => setCarType(val.target.value)} />
                            <label className={styles.searchButton} htmlFor="option3">Semestrale</label>

                            <input type="radio" className="btn-check" value="km 0" name="carType" id="option2" onChange={(val) => setCarType(val.target.value)} />
                            <label className={styles.searchButton} htmlFor="option2">KM 0</label>

                            <input type="radio" className="btn-check" value="usato" name="carType" id="option4" onChange={(val) => setCarType(val.target.value)} />
                            <label className={styles.searchButton} htmlFor="option4">Usato</label>

                            <input type="radio" className="btn-check" value="nuovo" name="carType" id="option1" onChange={(val) => setCarType(val.target.value)} />
                            <label className={styles.searchButton} htmlFor="option1">Nuovo</label>

                        </div>
                        <div className={styles.inputContainer}>
                            <div className="row">
                                <div className="col">
                                    <select className="w-100" style={{ padding: 12 }} onChange={(val) => { setCarBrand(val.target.value); setCarModel(""); }} required >
                                        <option value={""} selected>Marca</option>
                                        {filterList.brand ? Object.keys(filterList.brand).map((el) => {
                                            return (<option value={el}>{el}</option>)
                                        })
                                            : null
                                        }
                                    </select>
                                </div>
                                <div className="col">
                                    <select className="w-100" style={{ padding: 12 }} value={carModel} onChange={(val) => { setCarModel(val.target.value); }} required>
                                        <option value={""} selected>Modello</option>
                                        {filterList.brand && carBrand ?
                                            filterList.brand[carBrand].map((el) => {
                                                return (<option value={el}>{el}</option>)
                                            })
                                            : null
                                        }
                                    </select>
                                </div>
                                <div className="col-auto">
                                    <Button
                                        style={{ paddingTop: 12, paddingBottom: 12 }}
                                        label={"Mostra risultati"}
                                        variation={"tertiary"}
                                        onClick={() => navigate('veicoli', {
                                            state: {
                                                type: carType,
                                                brand: carBrand,
                                                model: carModel,
                                                maxPrice: maxPrice
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style={{ maxWidth: 1545, margin: 'auto' }}>
                <div className={"container py-3"}>
                    <h2 className="text-center" style={isMobile ? { marginTop: 26, marginBottom: 35 } : null}>Scopri le nostre ultime novità</h2>
                    <MyCarousel
                        desktop={3}
                        showPartial={true}
                        showArrow={true}
                        showArrowTablet={false}
                        showArrowMobile={false}
                        showDots={false}
                        showDotsTablet={true}
                        showDotsMobile={true}
                        containerClass="car-slider"
                    >
                        {carList.map((el, i) => {
                            return <CarPreview data={el} key={"car-preview-" + i} slideN={i} />
                        })}
                    </MyCarousel>
                    <Button
                        label={"Scopri tutte le offerte"}
                        variation={isMobile ? "tertiaryMobile" : "tertiary"}
                        onClick={() => window.location.href = "veicoli"}
                        style={isMobile ? { margin: "auto", marginTop: 48, fontSize: 13 } : { margin: "auto", marginTop: 48 }}
                    />
                </div>
            </section>

            <section style={{ margin: 'auto', marginBottom: 0, backgroundColor: "#D8DEE9" }}>
                <div className="container-fluid py-5" style={{ maxWidth: 1320 }}>
                    <h2 className="text-center">Perchè scegliere Gruppo Milanoauto</h2>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-3">
                            <div className={styles.whyRow} style={{ marginLeft: -45 }}>
                                <img src="site_images/svg/Shield.svg" width={44} height={44} className={styles.whyIcon} />
                                <h4>Oltre 30 anni di esperienza</h4>
                            </div>
                            <div className={styles.whyRow} style={{ marginLeft: -45 }}>
                                <img src="site_images/svg/Marchi.svg" width={48} height={48} className={styles.whyIcon} />
                                <h4 style={{ marginRight: -25 }}>Specializzati in Audi, Volkswagen e Porsche </h4>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-flex justify-content-center">
                            <img src="site_images/home_why.png" className={styles.whyImage} />
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className={styles.whyRow}>
                                <img src="site_images/svg/Finanziamento.svg" width={48} height={48} className={styles.whyIcon} />
                                <h4 style={{}}>La scelta vincente per semestrale e KM0</h4>
                            </div>
                            <div className={styles.whyRow}>
                                <img src="site_images/svg/Value.svg" width={48} height={48} className={styles.whyIcon} />
                                <h4 style={{}}>Se vuoi essere un cliente e non un numero </h4>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className={styles.porscheSection}
                //style={isMobile ? { margin:"auto", marginTop:32, fontSize: 13} : {margin:"auto", marginTop:32}}
                style={{ backgroundImage: "url(site_images/bg_home_porsche.png)" }}>
                <div className={"container py-5"}>

                    <h2 style={{ marginBottom: 0 }}>Entra nel mondo Porsche</h2>
                    <p style={{ marginBottom: 64 }}>del Gruppo Milanoauto</p>

                    <div className={"my-3"} />

                    <MyCarousel
                        desktop={3}
                        tablet={2}
                        mobile={1}
                        showPartial={true}
                    >
                        {porscheList.map((el, i) => {
                            return <CarPreview data={el} key={"car-preview-porsche-" + i} />
                        })}
                    </MyCarousel>
                    <div className={"my-3 mt-4"} />
                    <Button
                        label={"Scopri tutte le Porsche"}
                        variation={isMobile ? "tertiaryMobile" : "tertiary"}
                        onClick={() => window.location.href = "porsche"}
                        style={isMobile ? { margin: "auto", fontSize: 13 } : { margin: "auto" }}
                    />
                </div>
            </section>

            <section style={{ backgroundColor: "#D8DEE9" }}>
                <h2 className="text-center" style={isMobile ? { marginTop: 26, marginBottom: 35 } : null}>Noleggio</h2>
                <MyCarousel
                    desktop={3}
                    showPartial={true}
                    showArrow={false}
                    showArrowTablet={false}
                    showArrowMobile={false}
                    showDots={false}
                    showDotsTablet={false}
                    showDotsMobile={false}
                    infinite={false}
                    containerClass="car-slider"
                >
                    <div className={styles.homeCardContainer} >
                        <div className={styles.homeCard} style={{ backgroundImage: "url(site_images/home_card_1.png)" }} onClick={() => window.location.href = BASENAME + "noleggio-california"} >
                            <div className={styles.homeCardIcon}><img src="site_images/icon/Icon_Rent_Camper_home.png" /></div>
                            <div className={styles.homeCardSubtitle}>Partner freeway camper</div>
                            <div className={styles.homeCardTitle}>VW california</div>
                            <Button label="Scopri di piu" variation={"tertiary"} onClick={() => window.location.href=BASENAME + "noleggio-california"} />
                        </div>
                    </div>

                    <div className={styles.homeCardContainer}>
                    <div className={styles.homeCard} style={{ backgroundImage: "url(site_images/home_card_2.png)" }} onClick={() => window.location.href = BASENAME + "noleggio-veicoli-commerciali"} >
                            <div className={styles.homeCardIcon}><img src="site_images/icon/Icon_elettrico_home.png" /></div>
                            <div className={styles.homeCardSubtitle}>Mini-truck</div>
                            <div className={styles.homeCardTitle}>Veicoli commerciali</div>
                            <Button label="Scopri di piu" variation={"tertiary"} onClick={() => window.location.href=BASENAME + "noleggio-veicoli-commerciali"} />
                        </div>
                    </div>

                    <div className={styles.homeCardContainer}>
                <div className={styles.homeCard} style={{ backgroundImage: "url(site_images/home_card_3.png)" }} onClick={() => window.location.href = BASENAME + "noleggio-lungo-termine"} >
                            <div className={styles.homeCardIcon}><img src="site_images/icon/Icon_Import-car_home.png" /></div>
                            <div className={styles.homeCardSubtitle}>Privati e aziende</div>
                            <div className={styles.homeCardTitle}>Auto a lungo termine</div>
                            <Button label="Scopri di piu" variation={"tertiary"} onClick={() => window.location.href=BASENAME + "noleggio-lungo-termine"} />
                        </div>
                    </div>

                </MyCarousel>
            </section>


            <section style={{ marginTop: -20 }}>
                <h2 className="text-center" style={{ marginBottom: 40 }}>Siamo specializzati in</h2>
                <div className={styles.logoContainer}>
                    <div><img src="site_images/audi_home.png" /></div>
                    <div><img src="site_images/wolkswagen_home.png" /></div>
                    <div><img src="site_images/porsche_home.png" /></div>
                </div>
            </section>
            <section style={{ backgroundColor: "#D8DEE9" }}>
                <h2 className="text-center" style={isMobile ? { marginTop: 26, marginBottom: 35 } : null}>Assistenza</h2>
                <div className={styles.assistanceCardContainer}>

                    <div className={styles.assistanceCard}>
                        <img className={styles.assistanceCardImg} src="site_images/service_new_mobile.png" />
                        <div className={styles.assistanceCardBottomRow}>
                            <img src="site_images/icon/icon_officina.png" />
                            <div className={styles.assistanceCardText}>
                                <h3>Gruppo Milanoauto</h3>
                                <h4>Service</h4>
                            </div>
                            <Button variation={"tertiary"} label="Scopri di più" onClick={() => window.location.href=BASENAME + "service"}/>
                        </div>
                    </div>


                    <div className={styles.assistanceCard}>
                        <img className={styles.assistanceCardImg} src="site_images/carrozzeria_new_mobile.png" />
                        <div className={styles.assistanceCardBottomRow}>
                            <img src="site_images/icon/icon_preparazione.png" />
                            <div className={styles.assistanceCardText}>
                                <h3>Gruppo Milanoauto</h3>
                                <h4>Carrozzeria</h4>
                            </div>
                            <Button variation={"tertiary"} label="Scopri di più" onClick={() => window.location.href=BASENAME + "carrozzeria"}/>
                        </div>
                    </div>

                </div>
            </section>



            <section style={{ marginTop: -20 }}>
                <h2 className="text-center" style={isMobile ? { marginTop: 0, marginBottom: 35 } : null}>Dicono di noi</h2>
                <div className="text-center mb-4">
                    <img src="site_images/autoscout.png" />
                </div>

                <div className={styles.ratingContainer}>
                    <div className={styles.ratingExternalCard}>
                        <h4>Sede di Cologno Monzese</h4>
                        <div className={styles.ratingCard}>
                            <div className={styles.rate}>4,9</div>
                            <div className={styles.stars}>
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                            </div>
                            <div className={styles.ratingText}>
                                100% di recensioni positive
                            </div>
                            <img src="site_images/autoscout.png" />
                        </div>
                    </div>

                    <div className={styles.ratingExternalCard}>
                        <h4>Sede di Cormano</h4>
                        <div className={styles.ratingCard}>
                            <div className={styles.rate}>4,6</div>
                            <div className={styles.stars}>
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                                <FontAwesomeIcon icon={"star"} />
                            </div>
                            <div className={styles.ratingText}>
                                96% di recensioni positive
                            </div>
                            <img src="site_images/autoscout.png" />
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    );
}

